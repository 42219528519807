img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  opacity: 0.3;
}

.about__container > h1 {
  color: goldenrod;
  font-size: 40;
  margin-top: -40;
}

.about__container .flex-container {
  display: flex;
  align-items: center;
}

.flex-child {
  flex: 1;
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  border: 2px solid white;
  margin-right: 20px;
}

.flex-child:first-child {
  margin-right: 20px;
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  border: 2px solid white;
}

#x-2 {
  flex: 1;
  margin-right: 20px;
  margin-bottom: 5px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  border: 2px solid white;
}

#x-3 {
  flex: 1;
  margin-right: 20px;
  margin-bottom: 5px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  border: 2px solid white;
}

.tools__container{
  background-color: rgba(201, 6, 91, 0.672);
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  line-height: 5px;
  font-size: 1.2rem;
  position: sticky;
  top: 300px;
  z-index: 999;
}

.item h2{
  color: antiquewhite;
}

.item h3{
  color: aliceblue;
}

.break {
  flex-basis: 10%;
  height: 0;
}

@media screen and (max-width: 960px) {
  .tools__container{
    z-index: -1;
  }

}
