video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: white;
  font-size: 50px;
  margin-top: -100px;
}

.link-font {
  /* display: flex; */
  color: aqua;
  height: 100%;
}

.link-font:hover {
  color: gold;
  bottom: 5rem;
}

.link-font > h1 {
  color: white;
}

/* .hero-btns > h1 {
  margin-top: 32px;
  color: white;
} */

.hero-btns .btn {
  margin: 6px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.hero-container .flex-container {
  display: flex;
  align-items: center;
}

.flex-child {
  flex: 1;
  border: 2px solid yellow;
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.flex-child:first-child {
  margin-right: 20px;
  text-align: center;
}
