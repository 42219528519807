.project__container {
  position: relative;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  overflow: hidden;
  /* display: inline-block; */
}

.box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 50vw;
  display: flex;
  flex-direction: row;
  border: solid 1px green;
  position: relative;
  padding: 2px;
  margin: 10px auto;
}

.box {
  width: 150px;
  height: 100px;
  margin: 10px;
  transition: background-color 0.1s ease-in-out;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  position: relative;
}

.description__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 50vw;
  display: flex;
  flex-direction: row;
  border: solid 1px green;
  position: relative;
  padding: 2px;
  margin: 70px auto;
  overflow: auto;
}
.box > h2 {
  font-size: x-large;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  color: orange;
  text-align: center;
  margin-top: 25px;
  position: relative;
}
h4 {
  color: blue;
}
h3 {
  color: black;
}

h3,
h4 {
  display: inline;
}
li {
  line-height: 3em;
}

.text-link {
  background-color: royalblue;
  color: white;
  padding: 0.1px 5px;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 50%;
}

.text-link:hover {
  background-color: greenyellow;
  color: red;
}

.box img {
  top: 17%;
  position: absolute;
  align-content: center;
  opacity: 100%;
  width: 296px;
  height: 200px;
  z-index: 1;
  display: inline-flex;
  margin: auto;
}

.inactive {
  background-color: aqua;
}

.active {
  background-color: lime;
}

@media (max-width: 800px) {
  .box > h2 {
    font-size: small;
  }
}
@media (max-width: 800px) {
  .box {
    height: 50px;
  }
}

@media (max-width: 900px) {
  .box-wrapper {
    height: 40vh;
    width: 90vw;
  }
}

@media (max-width: 1500px) {
  .box-wrapper {
    height: 40vh;
    width: 90vw;
  }
}

@media (max-width: 355px) {
  .box-wrapper {
    height: 20vh;
    width: 100vw;
  }
}

@media (max-width: 1280px) {
  .description__container {
    height: 60vh;
    width: 90vw;
  }
}

@media (max-width: 800px) {
  h3,
  h4 {
    font-size: small;
  }
}

@media (max-width: 800px) {
  li {
    line-height: 1.5em;
  }
}
